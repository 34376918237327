$small-mobile-width: 320px;
$mobile-width: 530;
$tablet-width: 640px;
$large-tablet-width: 768px;
$desktop-width: 1024px;

@mixin small-mobile {
  @media (min-width: #{$small-mobile-width}) and (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$large-tablet-width - 1px}) {
    @content;
  }
}

@mixin large-tablet {
  @media (min-width: #{$large-tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
