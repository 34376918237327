@import "/src/styles/color";
@import "/src/styles/media";
.credit-score-banner {
  background-image: linear-gradient(
    to right,
    #e9eef0 30%,
    #e6f4f7 41%,
    #e6f4f7 76%,
    #e1e1e1 93%
  );
  svg {
    color: $purple-color;
  }
}
