@import "./color";
@import "/src/styles/media";

$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "accent": $accent-color,
  "light-gray": $light-gray,
  "dark-gray": $dark-gray,
  "white": $white,
  "black": $black,
);

nav.navbar {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.btn-secondary {
  background-color: $secondary-color !important;
  color: $white !important;
  border-color: $secondary-color !important;
  &:hover {
    background-color: darken($secondary-color, 10%) !important;
    border-color: darken($secondary-color, 10%) !important;
  }
}
