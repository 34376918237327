@import '/src/styles/color';
@import '/src/styles/media';
.about {
  display: block;
  .section {
    padding: 7rem;
    padding-bottom: 7rem;
    @include small-mobile {
      padding: 3rem 0.9rem;
    }
    .title {
      display: flex;
      flex-direction: column;
      &.left {
        align-items: flex-end;
        @include small-mobile {
          align-items: start;
        }
      }
      &.right {
        align-items: start;
      }
      h1 {
        font-family: monospace;
        @include small-mobile {
          margin-top: 1rem;
        }
      }
      h6 {
        font-family: ui-serif;
        letter-spacing: 2px;
      }
      p {
        font-size: 1.5rem;
        letter-spacing: 1px;
        padding-top: 2rem;
        line-height: 3rem;
        @include small-mobile {
          line-height: 1.5;
          font-size: 1rem;
          padding-top: 1rem;
        }
        @include large-tablet {
          line-height: 1.5;
        }
      }
      .founder-icons {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        font-size: 1.5rem;
      }
    }
    &.founder_1 {
      background: linear-gradient(90deg, #a6a6a5, #ffffff);
    }
    &.founder_2 {
      @include small-mobile {
        .reverse {
          display: flex;
          flex-direction: column-reverse;
        }
      }
      background: linear-gradient(90deg, #000000, #c89116);
      .image-div {
        display: flex;
        justify-content: flex-end;
        @include small-mobile {
          justify-content: start;
        }
      }
      .title {
        color: #ffde59;
      }
    }
    &.founder_3 {
      background: linear-gradient(90deg, #8c52ff, #ff914d);
      .title {
        color: $white;
      }
    }
  }
}
