@import "/src/styles/color";
@import "/src/styles/media";
.footer {
  background-color: rgba(0, 0, 0, 0.9);
  color: $white;
  padding: 1.5rem 0;

  .footer-link {
    color: $white;
    opacity: 0.6;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }

  h5,
  h6 {
    color: $white;
  }

  .footer-social {
    display: flex;
    justify-content: center;
    gap: 1.7rem;
    font-size: 1.7rem;
    @include desktop {
      justify-content: flex-end;
    }
  }

  .section-title {
    border-bottom: 4px solid $white;
    display: inline-block;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  .footer-legal {
    opacity: 0.6;
    color: $white;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 175%;
  }
}
