@import "/src/styles/color";
.logo {
    max-height: 100px;
    height: 100px;
    display: flex;
    align-items: center;
  }
  .circle {
    background-color: $accent-color;
    color: #5d5959;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
  }
  .text {
    color: $primary-color;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
  }
  .easy {
    font-size: 1.9rem;
    font-weight: 900;
    letter-spacing: 1px;
    border-bottom: 2px solid;
    line-height: 50px;
    position: relative;
  }
  .finance {
    letter-spacing: 4px;
    margin-top: 7px;
  }
  .coin{
    color: $accent-color;
    top: 11px;
    right: -8px;
    position: absolute;
    font-size: 1.5rem;
    transform: rotate(20deg);
  }
  .eee{
    margin-top: -25px;
    margin-left: 6px;
  }
  .fff{
    font-size: 2.5rem;
    margin-left: 5px;
    font-family: sans-serif;
    margin-top: 30px;
  }
  .eText{
    margin-left: 7px;
  }
