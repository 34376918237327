@import "/src/styles/color";
@import "/src/styles/media";
.header {
  background-color: $white;
  padding: 1rem 0;
  border-bottom: 1px solid $light-gray;
  .header-container {
    @include desktop {
      gap: 1.5rem;
      height: 110px;
      align-items: center;
    }
    .header-menu-link,
    a {
      color: $primary-color;
      font-family: serif;
      border-bottom: 1px solid;
      font-size: 1.1rem;
      letter-spacing: 1px;
      border: none;
      &:last-child {
        border-bottom: none;
      }
      @include desktop {
        height: 110px;
        font-size: 1.3rem;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        border-bottom: none;
      }
      svg {
        margin-left: 4px;
      }
      .header-menu-link-child {
        height: 40px;
        height: 40px;
        font-size: 1rem;
        letter-spacing: 0px;
        font-family: sans-serif;
        border-bottom: none;
      }
    }
  }
}
